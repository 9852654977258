
import IMask from 'imask';

window.IMaskDate = class IMaskDate{

  constructor(classSelector){
    this.classSelector = classSelector;
  }

  init(){

    let elements = document.getElementsByClassName(this.classSelector);
    console.log(elements);
    for (let ele of elements)
      this.initEle(ele)

  }

  initEle(ele){
    var momentFormat = 'DD/MM/YYYY';
    var momentMask = IMask(ele, {
      mask: Date,
      pattern: momentFormat,
      lazy: false,
      min: new Date(1900, 0, 1),
      max: new Date(),

      format: function (date) {
        return moment(date).format(momentFormat);
      },
      parse: function (str) {
        return moment(str, momentFormat);
      },

      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1900,
          to: 2030
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12
        },
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31
        },
        HH: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 23
        },
        mm: {
          mask: IMask.MaskedRange,
          from: 0,
          to: 59
        }
      }
    });
  }

}



