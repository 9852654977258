
window.RemoteModal = class RemoteModal{

  constructor(options){
    this.options = options;
  }

  init(){
    $(".open-remote-modal").on("click", (e)=>{
      (new RemoteModal({
        url: e.currentTarget.href,
        refreshTarget: $(e.currentTarget).data("refresh-target"),
        event: e
      })).open();
      return false;
    })
  }

  open(callback, closeCallback){
    this.options.shownCallback = callback;
    this.options.hiddenCallback = closeCallback;

    let promise = axios.get(this.options.url);
    promise.then( (response) => {
      if(response.data.success){

        $("#remote-modal").html(response.data.html);
        
        if(this.options.shownCallback){
          $("#remote-modal").on('shown.bs.modal', () => {
              this.options.shownCallback($("#remote-modal"));
          });
        }

        if(this.options.showCallback){
          $("#remote-modal").on('show.bs.modal', () => {
              this.options.showCallback($("#remote-modal"));
          });
        }

        if(this.options.hiddenCallback){
          $("#remote-modal").on('hidden.bs.modal', () => {
            this.options.hiddenCallback(
              $("#remote-modal"), $(this.options.event.currentTarget));
          });
        }

        if(this.options.hideCallback){
          $("#remote-modal").on('hide.bs.modal', () => {
            this.options.hideCallback(
              $("#remote-modal"), $(this.options.event.currentTarget)
            );
          });
        }

        $("#remote-modal").modal('show');


      } else {
        (new ToastPopper("Error obteniendo datos")).danger();
        console.log(response);
      }
      

    }).catch(function (error) {
      (new ToastPopper("Error obteniendo datos")).danger();
    });
  }

  close(){
    $("#remote-modal").modal('hide');
  }


}
