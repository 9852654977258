
window.ToastPopper = class ToastPopper{

  constructor(msg){
    this.msg = msg;
    this.initContainer();
  }

  info(){
    this.type = "info";
    this.icon = "info-circle";
    this.show();
  }

  success(){
    this.type = "success";
    this.icon = "check";
    this.show();
  }

  danger(){
    this.type = "danger";
    this.icon = "exclamation-circle";
    this.show();
  }

  warning(){
    this.type = "warning";
    this.icon = "exclamation-triangle";
    this.show();
  }

  love(){
    this.type = "success";
    this.icon = "heart";
    this.show();
  }

  show(){
    $(".toast-container").append(this.markup());
    $(".toast").on('hidden.bs.toast', function () {
      $(this).remove();
    });
    $(".toast-container .toast:last").toast('show');
  }

  initContainer(){
    if($(".toast-container").length == 0){
      $("body").append('<div class="position-fixed bottom-0 end-0 p-3 toast-container" style="z-index: 5000" ></div>');
    }
  }

  markup(){
    return `
            <div class="toast align-items-center bg-` + this.type + ` text-white  
            border-0" role="alert" aria-live="assertive" aria-atomic="true" style="width:500px;">
              <div class="d-flex">
                <div class="toast-body">
                  <i class="fa fa-` + this.icon + `" ></i>
                  `
                  + this.msg + 
                  `
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
              </div>
            </div>
          `;
  }

}


