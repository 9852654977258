
window.Market = class Market{


  init(){

    new CartControls({
      successCallback: (data)=>{
        $(".current-order-total").html(numberToCurrency(data.orderTotal));
      },
      showVerifiedEmailAlertCallback: () => {
        $("#show-verification-email-alert").modal('show');
      }
    }).init();

    let showCallback = (remoteModal)=>{
      $(".modal-backdrop").addClass("modal-backdrop-show");
      new CartControls({
        successCallback: (data)=>{
          $(".current-order-total").html(numberToCurrency(data.orderTotal));
        },
        showVerifiedEmailAlertCallback: () => {
          remoteModal.modal('hide');
          $("#show-verification-email-alert").modal('show');
        }
      }).init(remoteModal);
    };

    let closeCallback = (remoteModal, currentTarget)=>{
      let newQuantity = remoteModal.find(".quantity-selector").val();
      currentTarget
        .closest(".product")
        .find(".quantity-selector")
        .val(newQuantity);
    };

    $(".open-show-product").on("click", (e)=>{
      (new RemoteModal({
        event: e,
        url: e.currentTarget.href,
        refreshTarget: $(e.currentTarget).data("refresh-target"),
        hideCallback: closeCallback
      })).open(showCallback);
      return false;
    })

  }

}



