
window.numberToCurrency = function(number){
  return "$" + numberFormat(number, 0, ',');
}

window.numberFormat = function( value, decimals, separator ){

  if(  typeof value === 'string' ) value = value.replace( ',', '.' );

  if( !value || value == "" || isNaN( value ) ) return 0;

  if( typeof decimals === 'undefined' ){
    return parseFloat( value );
  }
  else{
    separator = typeof separator === 'undefined' ? '.' : separator;
    var numberFixed = parseFloat( value ).toFixed( decimals ).toString();
    return numberFixed.replace('.', separator);
  }

}
