require('alpinejs');
require('./bootstrap');
require('./RemoteModal');
require('./ToastPopper');
require('./numberToCurrency');
require('./CartControls');
require('./Market');
require('./Checkout');
require('./IMaskDate');


$(function(){
	initSelect2();

	$("a.submit").on("click", function (argument) {
		$(this).closest("form").submit();
		return false;
	});

	(new IMaskDate('datemask')).init();

});

function initSelect2(container){
	let elements = container ? $(container).find(".select2") : $(".select2");
	elements.select2({'dropdownCssClass': 'all'});
}


