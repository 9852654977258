
window.Checkout = class Checkout{

  init(){
    this.form = $("#finish-order");
    this.attachSubmitListeners();
    this.initCartControls();
    this.attachDeliveryTypeClickListener();
    this.attachUserAddressIdChangeListener();
    this.attachDonationChangeClickListener();
    this.checkoutTotal = parseFloat($(".checkout-total").data("amount"));
    this.deliveryPrice = parseFloat(0);
    this.donationAmount = parseFloat(0);
    this.totalWithExtras = this.checkoutTotal;

  }

  attachSubmitListeners(){
    this.form.on("submit", ()=>{
      if(this.totalWithExtras == 0){
        (new ToastPopper("Para confirmar el pedido tenés que seleccionar tus productos desde la tienda")).danger();
      } else {
        this.checkProductsAvailability();
      }
      return false;
    });
  }

  checkProductsAvailability(){
    axios.get(this.form.data("validate-product-availability-path")).then( (response) => {
      if(response.data.success){
        if(response.data.areAllProductsAvailable){
          this.form[0].submit();
        } else {
          this.showProductsUnavailableConfirmationModal();
        }
      } else {
        (new ToastPopper("Algo salió mal, volvé a intentar.")).danger();
      }
    }).catch(function (error) {
      (new ToastPopper(error)).danger();
    });
  }

  showProductsUnavailableConfirmationModal(){
    $("#products-unavailable-confirmation").modal("show");
    $("#products-unavailable-confirmation")[0].addEventListener('shown.bs.modal', event => {
      $("#products-unavailable-confirmation .confirm-checkout").off("click").on("click", ()=>{
        this.form[0].submit();
      });
    });

  }

  initCartControls(){
    new CartControls({
      successCallback: (data, productId)=>{
        this.cartControlsCallback(data, productId)
      }
    }).init();
  }

  cartControlsCallback(data, productId){
    $(".current-order-total").html(numberToCurrency(data.orderTotal));
    if(data.itemTotal == 0){
      $(".checkout-table .row_" + productId).fadeOut();
    } else {
      $(".checkout-table .row_" + productId)
        .find(".item-total")
        .html(numberToCurrency(data.itemTotal));
    }
    $(".checkout-table .checkout-total").html(numberToCurrency(data.orderTotal));
    this.checkoutTotal = parseFloat(data.orderTotal);
    this.setTotalWithExtras();
  }

  attachDeliveryTypeClickListener(){
    $(".delivery-type-selector").on("click", (e)=>{
      this.toggleAddress();
    });
  }

  attachUserAddressIdChangeListener(){
    $("#user_address_id, #hood_id").on("change", ()=>{
      this.setDeliveryPrice();
    });
  }

  attachDonationChangeClickListener(){
    $("#donation_amount").on("change", (e) => {
      this.donationAmount = parseFloat($(e.target).val());
      this.donationAmount = isNaN(this.donationAmount) ? 0.0 : this.donationAmount;
      let amountFormated = numberToCurrency(this.donationAmount);
      $(".donation-amount").html(amountFormated);
      (new ToastPopper("Tu donación será de : "+amountFormated)).love();
      this.setTotalWithExtras();
    });
  }

  toggleAddress(){
    let key = $(".delivery-type-selector:checked").data("key");
    if(key == 'home_delivery'){
      this.showAddress();
    } else {
      this.hideAddress();
    }
    this.setDeliveryPrice();
  }

  showAddress(){
    $(".user-addrress-container").removeClass("d-none");
  }

  hideAddress(){
    $(".user-addrress-container").addClass("d-none");
  }

  setDeliveryPrice(){
    let deliveryType = $(".delivery-type-selector:checked").data("key");
    let priceFormated = null;

    if(deliveryType == 'home_delivery'){
      if($("#user_address_id:visible").length > 0)
        this.deliveryPrice = parseFloat($("#user_address_id:visible option:selected").data("delivery_price"));
      else
        this.deliveryPrice = parseFloat($("#hood_id:visible option:selected").data("delivery_price"));
      this.deliveryPrice = isNaN(this.deliveryPrice) ? 0.0 : this.deliveryPrice;
      priceFormated = this.deliveryPrice == 0 ? "Precio a convenir" : numberToCurrency(this.deliveryPrice);
    } else {
      this.deliveryPrice = 0;
      priceFormated = numberToCurrency(0);
    }

    $(".delivery-price").html(priceFormated);
    (new ToastPopper("Costo del envío: "+priceFormated)).success();
    this.setTotalWithExtras();
  }

  setTotalWithExtras(){
    this.totalWithExtras = this.checkoutTotal + this.deliveryPrice + this.donationAmount;
    $(".total-with-delivery").html(numberToCurrency(this.totalWithExtras));
  }

}



